import React, { useState, useEffect } from "react";

const CountdownTimer = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
    const [isTablet, setIsTablet] = useState(window.innerWidth > 480 && window.innerWidth <= 768);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(targetDate));
        }, 1000);

        // Screen resize listener
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 480);
            setIsTablet(window.innerWidth > 480 && window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            clearInterval(timer);
            window.removeEventListener('resize', handleResize);
        };
    }, [targetDate]);

    function calculateTimeLeft(targetDate) {
        const now = new Date();
        const difference = new Date(targetDate) - now;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            };
        }

        return timeLeft;
    }

    const baseStyle = {
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Arial, sans-serif',
            color: '#fff',
            textAlign: 'center',
            gap: '10px',
            paddingTop: '2em',
            flexWrap: 'wrap', // Handle small screens
        },
        timeUnit: {
            fontSize: '2rem',
            fontWeight: 'bold',
            margin: '0 10px',
            padding: '8px',
            backgroundColor: '#cba35c',
            borderRadius: '5px',
        },
        label: {
            fontSize: '1rem',
            color: '#ddd',
        }
    };

    const tabletStyle = {
        timeUnit: {
            fontSize: '1.5rem',
            padding: '5px',
            margin: '0 5px',
        },
        label: {
            fontSize: '0.9rem',
        }
    };

    const mobileStyle = {
        container: {
            flexDirection: 'row',
            gap: '3px',
        },
        timeUnit: {
            fontSize: '1em',
            padding: '3px',
        },
        label: {
            fontSize: '0.5rem',
        }
    };

    const appliedStyles = {
        container: isMobile ? { ...baseStyle.container, ...mobileStyle.container } : baseStyle.container,
        timeUnit: isMobile ? { ...baseStyle.timeUnit, ...mobileStyle.timeUnit } :
            isTablet ? { ...baseStyle.timeUnit, ...tabletStyle.timeUnit } : baseStyle.timeUnit,
        label: isMobile ? { ...baseStyle.label, ...mobileStyle.label } :
            isTablet ? { ...baseStyle.label, ...tabletStyle.label } : baseStyle.label,
    };

    return (
        <div style={appliedStyles.container}>
            <div>
                <span style={appliedStyles.timeUnit}>{timeLeft.days}</span>
                <span style={appliedStyles.label}>Days</span>
            </div>
            <div>
                <span style={appliedStyles.timeUnit}>{timeLeft.hours}</span>
                <span style={appliedStyles.label}>Hours</span>
            </div>
            <div>
                <span style={appliedStyles.timeUnit}>{timeLeft.minutes}</span>
                <span style={appliedStyles.label}>Minutes</span>
            </div>
            <div>
                <span style={appliedStyles.timeUnit}>{timeLeft.seconds}</span>
                <span style={appliedStyles.label}>Seconds</span>
            </div>
        </div>
    );
};

export default CountdownTimer;
